import React from "react";
import {useFirebase} from "react-redux-firebase";
import {TableActions} from "./components/Table.Actions";
import {AlertModel, IncomeModel} from "../../model";
import {DateTime} from "luxon";
import {auth} from "../../firebase";

const useData = () => {
    const firebase = useFirebase();
    const [model, setModel] = React.useState(new IncomeModel());
    const [cAlert, setCAlert] = React.useState(new AlertModel());
    const [loading, setLoading] = React.useState(false);
    const [income, setIncome] = React.useState([]);
    const [gas, setGas] = React.useState([]);

    React.useEffect(() => {
        const current_user = auth.currentUser;
        if (!current_user) return
        const db = firebase.firestore();
        db.collection("income")
            .orderBy("createdAt", "desc")
            .where("tenant.id", '==', current_user.uid)
            .limit(24)
            .onSnapshot((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    list.push(data);
                });
                setIncome(list);
            });

        db.collection("gas")
            .orderBy("createdAt", "desc")
            .where("tenant.id", '==', current_user.uid)
            .limit(24)
            .onSnapshot((docs) => {
                const list = [];
                docs.forEach((doc) => {
                    const data = doc.data();
                    data.id = doc.id;
                    list.push(data);
                });
                setGas(list);
            });
    }, []);


    const incomeList = income.map((data) => ({
        ...data,
        id: data.id,
        createdAt: data.createdAt ? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '',
        actions: <TableActions data={data} type={'income'}  />,
    }));

    const gasList = React.useMemo(() => {
        return gas.map((data) => ({
            ...data,
            id: data.id,
            createdAt: data.createdAt ? DateTime.fromSeconds(data.createdAt.seconds).toFormat('DD') : '',
            actions: <TableActions data={data} type={'gas'}  />,
        }));

    }, [gas])

    return {
        incomeList,
        gasList,
        cAlert,
        setCAlert,
        loading,
    };
};

export default useData;
