import Icon from "@mui/material/Icon";
import Profile from "views/profile";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import Home from "views/home";
import Bank from "views/bank";
import Ruler from "views/ruler";

const routes = [
  {
    type: "navLink",
    name: "Inicio",
    key: "dashboard",
    route: "/dashboard",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    component: <Home />,
  },
  {
    type: "navLink",
    name: "Cuentas de Banco",
    key: "bank",
    route: "/bank",
    icon: <AccountBalanceIcon />,
    component: <Bank />,
  },
  {
    type: "navLink",
    name: "Normas",
    key: "ruler",
    route: "/ruler",
    icon: <Icon fontSize="medium">ruler</Icon>,
    component: <Ruler />,
  },
  {
    type: "navLink",
    name: "Perfil",
    key: "perfil",
    route: "/perfil",
    icon: <Icon fontSize="medium">manage_accounts</Icon>,
    component: <Profile />,
  },
];

export default routes;
