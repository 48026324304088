import { SESSION, GET_USERINFO } from "../actions";
import {TenantsModel} from "../../model";

export const INITIAL_STATE = {
  session: null,
  userInfo: new TenantsModel(),
};

export default function Reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SESSION:
      return { ...state, session: action.payload };
    case GET_USERINFO:
      return { ...state, userInfo: action.payload };
    default:
      return state;
  }
}
