import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import Footer from "../../examples/Footer";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import useData from "./data";

export default function Ruler() {
    const {
        rules,
    } = useData();
    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <h1>Normas De Convivencia</h1>
            <MDBox mt={4}>
                <h4 style={{color: "blue", whiteSpace: 'pre-wrap'}}>{rules}</h4>
            </MDBox>

            <Footer/>
        </DashboardLayout>
    );
}
