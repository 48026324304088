
import PropTypes from "prop-types";

import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import typography from "assets/theme/base/typography";
import styles from "./styles.css"

function Footer({company}) {
    const {href, name} = company;
    const {size} = typography;

    return (
        <MDBox mt={10}>
            <MDBox
                display="flex"
                flexWrap="wrap"
                color="text"
                fontSize={size.sm}
            >
                <MDTypography variant="button" fontWeight="medium">
                    GRACIAS POR CONFIAR EN LOS SERVICIOS DE INVERSIONES YOJPICH
                </MDTypography>
                <div class="whatsapp">
                    <a href="https://api.whatsapp.com/send?phone=18292018317" target="_blank" title="Contactame por Whatsapp">
                    <img src="https://www.peengler.com/wp-content/uploads/whatsapp.png" alt="WhatsApp" />
                    </a>
                </div>
            </MDBox>
            <MDBox
                width="100%"
                display="flex"
                flexDirection={{xs: "column", lg: "row"}}
                justifyContent="space-between"
                alignItems="center"

            >
                <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                    color="text"
                    fontSize={size.sm}
                >
                    &copy; {new Date().getFullYear()}, hecho con
                    <MDBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}>
                        <Icon color="inherit" fontSize="inherit">
                            favorite
                        </Icon>
                    </MDBox>
                    por
                    <Link href={href} target="_blank">
                        <MDTypography variant="button" fontWeight="medium">
                            &nbsp;{name}&nbsp;
                        </MDTypography>
                    </Link>
                </MDBox>
            </MDBox>
        </MDBox>

    );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
    company: {href: "#", name: "Ecorp Code"},
};

Footer.propTypes = {
    company: PropTypes.objectOf(PropTypes.string),
    links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
