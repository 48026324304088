import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import DataTable from "../../examples/Tables/DataTable";
import useData from "./data";
import Footer from "../../examples/Footer";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

export default function Bank() {
    const {
        bankList,
    } = useData();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <h1>Cuentas de Banco</h1>
            <MDBox mt={4}>
                <DataTable
                    table={{
                        columns: [
                            {Header: "banco", accessor: "bank"},
                            {Header: "cuenta", accessor: "account"},
                            {Header: "titular", accessor: "headline"},
                            {Header: "tipo de cuenta", accessor: "type"},
                        ],
                        rows: bankList,
                    }}
                />
                <br/>
                <h4 style={{color: "red"}}>NOTA: Cuando realice el deposito o transferencia agregue de comentario el
                    nombre de su residencial y apartamento. Ejemplo: Res. Gurabo Apt. A-1</h4>
            </MDBox>

            <Footer/>
        </DashboardLayout>
    );
}
