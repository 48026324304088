import React from "react";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import {jsPDF} from "jspdf";
import {DateTime} from "luxon";
import logo from '../../../assets/logo.png'

export function TableActions({data, type}) {

    var formatter = new Intl.NumberFormat('es-do', {
        style: 'currency',
        currency: 'DOP',
    });


    function PdfIncome(data) {
        const doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true,
            floatPrecision: 16,
            compress:true,
        });

        doc.addImage(logo, "JPEG", 15, 40, 40, 40);
        doc.text('Fecha: ' + DateTime.fromSeconds(data.createdAt.seconds).toFormat('dd-MM-yyyy'), 15, 90);
        doc.text('Teléfono: (800) 200-300', 15, 100);

        doc.text('Cliente: ' + data.tenant.label, 190, 80, null, null, "right");
        doc.text('Residencial: ' + data.residential.label, 190, 90, null, null, "right");
        doc.text('Apartamento: ' + data.apartment.label, 190, 100, null, null, "right");

        if (type === 'income') {
            doc.text("Concepto: Pago mantenimiento", 105, 130, null, null, "center");
            doc.text(data.monthsPaid.map(m => {
                return m.label
            }).join(', '), 105, 140, null, null, "center");
            doc.text("Total pagado: " + formatter.format(data.amount), 105, 150, null, null, "center");
        }

        if (type === 'gas') {
            doc.text("Concepto: Pago gas", 105, 130, null, null, "center");
            doc.text("Total pagado: " + formatter.format(data.amount), 105, 140, null, null, "center");
        }

        doc.save(`Factura-${DateTime.fromSeconds(data.createdAt.seconds).toFormat('dd-MM-yyyy')}.pdf`);
    }


    return [
        <IconButton
            onClick={() => PdfIncome(data)}
            key={2}
            style={{marginRight: 2}}
            variant="gradient"
            color="warning"
            size="medium"
        >
            <Icon>print</Icon>
        </IconButton>
    ];
}
