import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import DataTable from "../../examples/Tables/DataTable";
import CAlertModal from "../../components/Custom/CAlertModal";
import LoadingModal from "../../components/Custom/Loading";
import useData from "./data";
import Footer from "../../examples/Footer";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";

export default function Income() {
    const {
        incomeList,
        gasList,
        cAlert,
        setCAlert,
        loading,
    } = useData();

    return (
        <DashboardLayout>
            <DashboardNavbar/>
            <h1>Historial Pago De Mantenimiento</h1>
            <MDBox mt={4}>
                <DataTable
                    table={{
                        columns: [
                            {Header: "fecha", accessor: "createdAt"},
                            {Header: "residencial", accessor: "residential.label"},
                            {Header: "apt.", accessor: "apartment.label"},
                            {Header: "monto", accessor: "amount"},
                            {Header: "via", accessor: "bank.label"},
                            {Header: "comentario", accessor: "comment"},
                            {accessor: "actions"},
                        ],
                        rows: incomeList,
                    }}
                />
            </MDBox>
            <br/>
            <h1>Historial Consumo De Gas</h1>
            <MDBox mt={4}>
                <DataTable
                    table={{
                        columns: [
                            {Header: "Creado en", accessor: "createdAt"},
                            {Header: "Residencial.", accessor: "residential.label"},
                            {Header: "apt", accessor: "apartment.label"},
                            {Header: "gal", accessor: "gallonsQty"},
                            {Header: "p/gal", accessor: "price"},
                            {Header: "Importe", accessor: "amount"},
                            {Header: "Estado de pago", accessor: "paymentStatus"},
                            {Header: "via", accessor: "bank.label"},
                            {accessor: "actions"},
                        ],
                        rows: gasList,
                    }}
                />
            </MDBox>
            <CAlertModal alert={cAlert} close={setCAlert}/>
            <LoadingModal loading={loading}/>
            <Footer/>
        </DashboardLayout>
    );
}
