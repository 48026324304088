import React from "react";
import { useFirebase } from "react-redux-firebase";
import {useSelector} from "react-redux";

const useData = () => {
  const firebase = useFirebase();
  const model = useSelector((state)=>state.userInfo);
  const [rules, setRules] = React.useState('');

  React.useEffect(() => {
    if ( model.residential){
      const db = firebase.firestore();
      db.collection("rules")
          .where("residential.id", "==", model.residential.id)
          .limit(1)
          .get()
          .then((docs) => {
            docs.forEach((doc) => {
              const data = doc.data();
              setRules(data.rules)
            });
          });
    }
  }, [model]);

  return {
    rules,
  };
};

export default useData;
