import {auth, firestore} from "../../firebase";

export const GET_USERINFO = 'GET_USERINFO';
export function success(payload) {
  return {
    type: GET_USERINFO,
    payload,
  };
}

export function getUserInfo() {
  return async function (dispatch) {
    const current_user = auth.currentUser;
    const usersOnSnapshot = firestore
      .collection('tenants')
      .doc(current_user.uid)
      .onSnapshot(async querySnapshot => {
        if (!querySnapshot) {
          return;
        }
        if (querySnapshot.data() !== undefined) {
          let data = querySnapshot.data();
          data.id = current_user.uid;
          dispatch(success({...data, subscribed: usersOnSnapshot}));
        }
      });
  };
}
