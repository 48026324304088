import React from "react";
import { useFirebase } from "react-redux-firebase";

const useData = () => {
  const firebase = useFirebase();
  const [bank, setBank] = React.useState([]);

  React.useEffect(() => {
    const db = firebase.firestore();
    db.collection("bank")
        .orderBy("createdAt", "desc")
        .onSnapshot((docs) => {
          const list = [];
          docs.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            list.push(data);
          });
          setBank(list);
        });
  }, []);

  const bankList = bank.map((data) => ({
    ...data,
    id: data.id,
  }));

  return {
    bankList,
  };
};

export default useData;
