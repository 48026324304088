import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function Header({logout, model}) {

    return (
        <Card id="profile">
            <MDBox p={2}>
                <Grid container spacing={3} alignItems="center">
                    <Grid item>
                        <MDBox height="100%" mt={0.5} lineHeight={1}>
                            <MDTypography variant="h5" fontWeight="medium">
                                {`${model.firstName} ${model.lastName}`}
                            </MDTypography>
                            <MDTypography variant="button" color="text" fontWeight="medium">
                                Residencial: {`${model.residential?.label}`}
                            </MDTypography>
                            <MDBox height="100%">
                                <MDTypography variant="button" color="text" fontWeight="medium">
                                    Apartamento: {`${model.apartment?.label}`}
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} sx={{ml: "auto"}}>
                        <MDBox
                            display="flex"
                            justifyContent={{md: "flex-end"}}
                            alignItems="center"
                            lineHeight={1}
                        >
                            <MDBox>
                                <MDButton size={'small'} onClick={logout} color="error">
                                    Cerrar sesión
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default Header;
