const fbconfig = {
  apiKey: "AIzaSyAWv2wX3t5QP4yySvB7rbhwoQcPZjvatpM",
  authDomain: "bs-apartment-1.firebaseapp.com",
  projectId: "bs-apartment-1",
  storageBucket: "bs-apartment-1.appspot.com",
  messagingSenderId: "487123048329",
  appId: "1:487123048329:web:f8d97596f2beff14797358",
};

export { fbconfig };

// const fbconfig = {
//   apiKey: process.env.REACT_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_FIREBASE_AUTH_DOMAIN,
//   projectId: process.env.REACT_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_FIREBASE_APP_ID,
// };
